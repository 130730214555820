let rh = require("../../lib/rh")
let _ = rh._

_.addParam = (url, key, value) => {
  let hashStr = _.extractHashString(url)
  let paramsStr = _.extractParamString(url)
  let paramsMap = _.urlParams(paramsStr)
  paramsMap[key] = value
  let strippedUrl = _.stripBookmark(url)
  strippedUrl = _.stripParam(strippedUrl)

  let urlHashStr = (hashStr === '')? hashStr : '#'+ hashStr

  let updatedParamsStr = rh._.mapToEncodedString(paramsMap)
  let urlParamsStr = (updatedParamsStr === '')?updatedParamsStr: '?' + updatedParamsStr
  return strippedUrl + urlParamsStr + urlHashStr
}

_.removeParam = (url, param) => {
  let hashStr = _.extractHashString(url)
  let paramsStr = _.extractParamString(url)
  let paramsMap = _.urlParams(paramsStr)
  paramsMap[param] = null
  let strippedUrl = _.stripBookmark(url)
  strippedUrl = _.stripParam(strippedUrl)

  let urlHashStr = (hashStr === '')? hashStr : '#'+ hashStr

  let updatedParamsStr = rh._.mapToEncodedString(paramsMap)
  let urlParamsStr = (updatedParamsStr === '')?updatedParamsStr: '?' + updatedParamsStr
  return strippedUrl + urlParamsStr + urlHashStr
}

_.createHashedUrl = (url) =>{
  //let hashedUrl = url
  let relUrl, params
  if(!_.isRootUrl(url)){
    let rootUrl = _.getRootUrl()
    if(_.isExternalUrl(url)){
      relUrl = url
    }
    else{
      params = _.getParamsForRoot(url, true)
      relUrl = _.fixRelativeUrl( _.makeRelativePath (url, rootUrl))
      url = `${rootUrl}${params}#t=${encodeURIComponent(relUrl)}`
    }
  }
  return url
}

_.getOrigin = (srcWindow) => {
  return _.isLocal() || !srcWindow ? '*' : srcWindow.location.origin
}
