let rh = require("../../lib/rh");
let consts = rh.consts;
let $ = rh.$;
let model = rh.model;

model.subscribe(consts('EVT_PROJECT_LOADED'), () =>{
  let $html = $('html',0);
  let lang = model.get(consts('KEY_LNG_NAME'))
  if($html && lang && lang !== ''){  
    $.setAttribute($html, 'lang', lang)
  }
})

model.subscribe(consts('KEY_AND_SEARCH'), value => value === '' && model.publish(consts('KEY_AND_SEARCH'), '1'))
