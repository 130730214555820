
// to support old browser

if (String.prototype.trim == null) {
  String.prototype.trim = function() {
    return String(this).replace(/^\s+|\s+$/g, '')
  };
}

if (String.prototype.trimLeft == null) {
  String.prototype.trimLeft = function () {
    return String(this).replace(/^\s+/, '')
  }
}

if (String.prototype.trimRight == null) {
  String.prototype.trimRight = function () {
    return String(this).replace(/\s+$/, '')
  }
}

if (String.prototype.trimStart == null) {
  String.prototype.trimStart = String.prototype.trimLeft
}

if (String.prototype.trimEnd == null) {
  String.prototype.trimEnd = String.prototype.trimRight
}
