let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;

const NO_CHILD_ATTR = 'data-nochild'
const NO_CHILD_IGNORE = 'ignore'

class NodesUtil {
  constructor() {
    this.className = 'rh-hide'
  }


  everyChild(parent, callback) {
    let bRet = true;
    for (let child of Array.from(parent.children)) {
      bRet = bRet && callback(child);
      if(!bRet) {
        break;
      }
    }
    return bRet
  }

  isMarkedNode(node) {
    return !!node && $.hasAttribute(node, NO_CHILD_ATTR)
  }

  shouldHide(node) {
    let bHide = false
    if(node && this.isMarkedNode(node)) {
      bHide |= this.everyChild(node, child => {
        return $.hasClass(child, this.className) || ($.getAttribute(child, NO_CHILD_ATTR) === NO_CHILD_IGNORE);
      });
    }
    return bHide
  }

  onShow(node, dontCheckChidlren = false) {
    let bShown = false;
    if(node && (dontCheckChidlren || !this.shouldHide(node))) {
      $.removeClass(node, this.className);
      bShown = true;
      node.parentNode && this.isMarkedNode(node.parentNode) && this.onShow(node.parentNode, true);
    }
    return bShown
  }

  onHide(node) {
    if(!node) {
      return false;
    }
    $.addClass(node, this.className);
    if(this.shouldHide(node.nextElementSibling)) {
      this.onHide(node.nextElementSibling);
    }
    if(this.shouldHide(node.parentNode)) {
      this.onHide(node.parentNode);
    }
    return true;
  }
}

module.exports = new NodesUtil