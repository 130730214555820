let rh = require("../../lib/rh")
let _ = rh._
let keyHash = {
  8:  "backspace",
  13: "return",
  27: "escape",
  38: "down",
  40: "up",
  39: "right"
}

_.getKeyIndex = (keyCode) => {
  if(keyHash[keyCode]) {
    return keyHash[keyCode]
  } else {
    return "default"
  }
}
