let { rh } = window;
let { $ } = rh;
let { _ } = rh;
let { consts } = rh;

var Edwidget = (function() {
  let valueSeperator = undefined;
  Edwidget = class Edwidget {
    static initClass() {
      valueSeperator = {
        'data-rhwidget': ';',
        class: ' '
      };
    }

    constructor(node, index, arg) {
      this.node = node;
      this.index = index;
      let {view, attrs, model, argModel} = this.parseArg(arg);
      if (attrs) { this.setAttributes(this.node, attrs); }
      if (model) { this.setModelArg(this.node, _.extend({}, model, argModel)); }
      if (view) { this.createView(view, argModel); }
    }
    
    parseArg(arg) {
      let argModel, attrs, config, model, view;
      let {wName, wArg, pipedArgs} = arg;
      if (config = rh.edWidget(wName)) {
        let vars;
        ({view, attrs, vars, model} = config);
        argModel = _.resolveNiceJSON(pipedArgs.shift());
        this.vars = _.extend({}, vars, wArg);
      }
      return {view, attrs, model, argModel};
    }

    createView(view, argModel) {
      let viewModel = _.extend({}, view.model, argModel);
      let viewNode = document.createElement(view.tag || 'div');
      if (view.attrs) { this.setAttributes(viewNode, view.attrs); }
      this.setModelArg(viewNode, viewModel);
      return this.node.parentNode.insertBefore(viewNode, this.node);
    }

    setAttributes(node, attrs) {
      return _.each(attrs, function(value, attr) {
        return $.setAttribute(node, attr, this.resolveValue(this.mergedValue(node, attr, value)));
      }
      , this);
    }

    setModelArg(node, model) {
      if (!_.isEmptyObject(model)) {
        let jsonString = this.resolveValue(JSON.stringify(model));
        return $.dataset(node, 'rhwidget',
          `${$.dataset(node, 'rhwidget') || 'Basic'} | ${jsonString}`);
      }
    }

    mergedValue(node, attrib, value) {
      let oldValue, seperator;
      if (!(seperator = valueSeperator[attrib])) { return value; }
      if ((oldValue = $.getAttribute(node, attrib) || '')) {
        return `${oldValue}${seperator}${value}`;
      } else {
        return value;
      }
    }

    resolveValue(value) {
      return _.resolveEnclosedVar(value, varName => {
        switch (varName) {
          case 'this':
            return JSON.stringify(this.vars);
          case '@index':
            return this.index;
          default:
            return this.vars[varName];
        }
    });
    }
  };
  Edwidget.initClass();
  return Edwidget;
})();

/*
  <div data-edwidget="Tab: <name>: <string value>, <name>: <string value>
   | <json or niceJSON>"></div>
*/

rh.model.subscribe(consts('EVT_WIDGET_BEFORELOAD'), () =>
  _.each($.find(document, '[data-edwidget]'), function(node, index) {
    let args = _.resolveWidgetArgs($.dataset(node, 'edwidget'));
    _.each(args, arg => new Edwidget(node, index, arg));
    return $.dataset(node, 'edwidget', null);
  })
);

rh.edWidget = _.cache(_.isObject);
