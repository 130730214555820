/*
 Help for Edwidget
*/

let { edWidget } = window.rh;

/*
 Tab edwidget
*/
edWidget('tab', {
  attrs: {
    'data-table': 'data',
    'data-rhwidget': 'Basic',
    'data-output': 'data: edw.data.#{@index}',
    class: 'print-only'
  },
  view: {
    tag: 'div',
    attrs: {
      'data-rhwidget': 'Basic: include: edwidgets/tab/tabLayout.js',
      'data-input': 'data: edw.data.#{@index}'
    },
    model: {
      tab: '0'
    }
  }
}
);

/*
 Gallary edwidget
*/
//edWidget 'Gallary'