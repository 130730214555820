let { rh } = window;
let { consts } = rh;

// Project specific model keys
consts('KEY_PROJECT_TOPICLIST',          '.p.topiclist');
consts('KEY_PROJECT_BRSLIST',            '.p.brslist');
consts('KEY_PROJECT_TAG_COMBINATIONS',   '.p.tag_combinations');
consts('KEY_PROJECT_TAG_STATES',         '.p.tag_states');
consts('KEY_MERGED_FILTER_KEY',          '.p.tags');
consts('KEY_PROJECT_FILTER_CAPTION',     '.p.filter_caption');
consts('KEY_PROJECT_FILTER_TYPE',        '.p.filter_type');
consts('KEY_PROJECT_LIST',               '.p.projects');
consts('KEY_MASTER_PROJECT_LIST',        '.p.masterprojects');
consts('KEY_SEARCH_RESULTS', '.p.searchresults');
consts('MAX_SEARCH_RESULTS', 'max_search_results')
consts('KEY_SEARCH_RESULT_PARAMS',       '.p.searchresultparams');
consts('KEY_ONSEARCH_TAG_STATES',        '.p.onsearchtagstates');
consts('KEY_LNG',                        '.p.lng_db');
consts('KEY_DEFAULT_FILTER',             '.p.deffilter');
consts('PROJECT_GLOSSARY_DATA',          '.p.glodata');
consts('PROJECT_INDEX_DATA',             '.p.idxdata');

// Merged Project specific
consts('KEY_MERGED_PROJECT_MAP',         '.mp.tmap');

// Topic specific model keys
consts('KEY_TOPIC_URL',                 '.t.topicurl');
consts('KEY_TOPIC_ID',                  '.t.topicid');
consts('KEY_TOPIC_TITLE',               '.t.topictitle');
consts('KEY_TOPIC_BRSMAP',              '.t.brsmap');
consts('KEY_TOPIC_ORIGIN',              '.t.origin');
consts('KEY_TOPIC_HEIGHT',              '.t.topic_height');
consts('KEY_TOPIC_CONTENT_SIZE',        '.t.topic_content_size');
consts('GO_TO_DEFAULT_TOPIC',           '.t.gotodefaultTopic');


// Layout specific model keys
consts('KEY_SHOW_SCROLL_TO_TOP',        '.l.show_scroll_to_top');
consts('KEY_DO_NOT_PRESERVE_AR',        '.l.do_not_preserve_ar');
consts('KEY_SEARCH_TERM',               '.l.searchterm');
consts('KEY_SEARCHED_TERM',             '.l.searched_term');
consts('KEY_TAG_EXPRESSION',            '.l.tag_expression');
consts('KEY_UI_MODE',                   '.l.uimode');            // RH11
consts('KEY_BASE_IFRAME_NAME',          '.l.base_iframe_name');
consts('KEY_CAN_HANDLE_SERCH',          '.l.can_handle_search'); // RH11
consts('KEY_CAN_HANDLE_SEARCH',         '.l.can_handle_search');
consts('KEY_CSH_MODE',                  '.l.csh_mode');
consts('KEY_ONSEARCH_TAG_EXPR',         '.l.onsearchtagexpr');
consts('KEY_AND_SEARCH',                '.l.andsearch');
consts('KEY_FEATURE',                   '.l.features');
consts('KEY_SEARCH_PROGRESS',           '.l.search_progress');
consts('KEY_LAYOUT_VERSION',            '.l.layout_version');
consts('KEY_TOPIC_IN_IFRAME',           '.l.topic_in_iframe');
consts('KEY_SHOW_TAGS',                 '.l.showtags');
consts('KEY_DIR',                       '.l.dir');
consts('KEY_SEARCH_LOCATION',           '.l.search_location');
consts('KEY_DEFAULT_SEARCH_LOCATION',   '.l.default_search_location');
consts('KEY_FILTER_LOCATION',           '.l.filter_location');
consts('KEY_ACTIVE_TAB',                '.l.active_tab');
consts('KEY_DEFAULT_TAB',               '.l.default_tab');
consts('KEY_ACTIVE_TOPIC_TAB',          '.l.active_topic_tab');
consts('KEY_TOC_DRILL_DOWN',            '.l.toc_drilldown');
consts('KEY_MOBILE_TOC_DRILL_DOWN',     '.l.mobile_toc_drilldown');
consts('KEY_PUBLISH_MODE',              '.l.publish_mode');
consts('KEY_PUBLISH_BASE_URL',          '.l.publish_base_url');
consts('KEY_PROJECTS_BASE_URL',         '.l.projects_base_url');
consts('KEY_INDEX_FILTER',              '.l.idxfilter');

// Events
consts('EVT_BASE_IFRAME_LOAD',           '.e.base_iframe_load');
consts('EVT_SCROLL_TO_TOP',              '.e.scroll_to_top');
consts('EVT_SEARCH_TERM',                '.e.search_term');
consts('DESKTOP_SIDEBAR_VISIBLE',        '.l.desktop_sidebar_visible');
consts('EVT_NAVIGATE_TO_URL',            '.e.navigate_to_url');
consts('EVT_PROJECT_LOADED',             '.e.project_loaded');
consts('EVT_TOC_LOADED',                 '.e.toc_loaded');
consts('EVT_TOPIC_LOADED',               '.e.topic_loaded');
consts('EVT_TOPIC_LOADING',              '.e.topic_loading');
consts('EVT_BOOKMARK',                   '.e.bookmark.');
consts('EVT_PRINT_TOPIC',                '.e.print_topic');
consts('EVT_SEARCH_IN_PROGRESS',         '.e.search_in_progress');
consts('EVT_RELOAD_TOPIC',               '.e.reload_topic');
consts('EVT_QUERY_SEARCH_RESULTS',       '.e.query_search_results');
consts('EVT_LOAD_IDX',                   '.e.load_idx');
consts('EVT_LOAD_GLO',                   '.e.load_glo');
consts('EVT_TOC_SELECTED_ITEM_CHANGED', 'evt.toc_select_item_changed')

// Hash and query keys
consts('HASH_KEY_RH_HIGHLIGHT',          'rhhlterm');
consts('HASH_KEY_RH_SYNS',               'rhsyns');
consts('HASH_KEY_RH_SEARCH',             'rhsearch');
consts('HASH_KEY_RH_TOCID',              'rhtocid');

// Hash keys
consts('HASH_KEY_TOPIC',                 't');
consts('HASH_KEY_UIMODE',                'ux');
consts('HASH_KEY_RANDOM',                'random');

consts('PATH_PROJECT_TAGDATA_FILE',      'whxdata/whtagdata.js');
consts('CORDOVA_JS_URL',                 'cordova.js');
consts('RHS_LOG_TOPIC_VIEW',              {mgr: 'sys', cmd: 'logtpc'});
consts('RHS_DO_SEARCH',                   {mgr: 'agm', agt: 'nls', cmd: 'search'});
consts('KEY_MOBILE_APP_MODE',           '.m.mobileapp');
