let rh = require("./rh")
let consts = rh.consts

consts('RHMAPID', 'rhmapid');
consts('RH_FULL_LAYOUT_PARAM', 'rhfulllayout');

consts('EVT_TOPIC_WIDGET_LOADED', '.e.topic_widget_loaded');
consts('EVT_CLOSE_SEARCH_SUGGESTION', '.e.close_search_suggestion');

consts('KEY_TOC_BREADCRUMBS', '.p.toc_breadcrumbs');
consts('KEY_TOC_SELECT_ITEM', '.p.toc_select_item');
consts('KEY_TOC_BACK_BUTTON_PRESSED', '.p.toc_back_pressed_button');
consts('KEY_SHOW_TOC_BACK_BUTTON', '.p.show_toc_back_button');


consts('KEY_TOC_ORDER', '.p.toc_order');
consts('KEY_TOC_CHILD_ORDER', '.p.toc_child_order');
//Search specific
consts('SEARCH_MAP_ADDR', "whxdata/search_auto_map_0.js");
consts('SEARCH_MAP_ADDR_OLD', "whxdata/searchAuto_Map_0.js");
consts('SEARCH_MODEL_ADDR', "whxdata/search_auto_model_");
consts('SEARCH_MODEL_ADDR_OLD', "whxdata/searchAuto_Model_");
consts('SEARCH_INDEX_DATA', ".l.search_index_data");
consts('SEARCH_INDEX_FILE', "whxdata/search_auto_index.js");
consts('SEARCH_INDEX_FILE_OLD', "whxdata/searchAuto_Index.js");
consts('SEARCH_DB_FILE', "whxdata/search_db.js");
consts('SEARCH_METADATA_FILE', "whxdata/search_topics.js")
consts('SEARCH_TEXT_FILE', "whxdata/text")
consts('SEARCH_TINY_SEGMENTER', "whxdata/languages/tinysegmenter.js")
consts('SEARCH_STEMMER_SUPPORT', "whxdata/languages/stemmer.js")
consts('SEARCH_LNG_SUPPORT', "whxdata/languages/lng_support.js")



consts('SEARCH_MODEL_KEY','.l.search_model.');
consts('SEARCH_MAP_KEY', '.l.search_map.');
consts('SEARCH_MAX_TOPICS', 20);
consts('SEARCH_RESULTS_KEY', 'search_results');
consts('STOP_NAVIGATE_TO_TOPIC', '.l.stoptopicnav');
consts('SEARCH_WORDS_MAP', '.l.search_words_map');
consts("MAX_SEARCH_INPUT", 3);
consts("KEY_BREADCRUMBS", '.l.breadcrumbs');
consts('HASH_HOMEPAGE_MODE', 'homepage');
consts('KEY_VIEW_MODE', '.l.mode');
consts('HELP_LAYOUT_MODE', 'layout');
consts('HELP_SEARCH_MODE', 'search');
consts('HELP_TOPIC_MODE', 'topic');
consts('PREV_SEARCH_KEY', 'data-prev-search');
consts('KEY_SUBSTR_SEARCH', '.substr_search');
consts('KEY_SHOW_CONTEXT', '.show_srxh_ctxt');

	/* favorites constants */
consts('FAVATTRIBUTE','data-favwidget');
consts('FAVBUTTON','fav-button');
consts('FAVLIST','fav-list');
consts('FAVSTORAGE', 'fav-store');

consts('FAVLINKCLASS','favorite');
consts('UNFAVLINKCLASS','unfavorite');
consts('FAVTABLECLASS', 'favoritesholder');
consts('FAVTABLETITLECLASS', 'favorite');
consts('FAVTABLEREMOVECLASS', 'removelink');
consts('FAVLISTINTROCLASS', 'favoritesintro');
consts('FAVLISTTABLEINTROCLASS', 'favoritestableintro');
consts('EVENTFAVCHANGE', 'favorite-changed-in-script');
consts('TOPIC_FAVORITE', '.l.topic_favorite');
consts('KEY_FAVORITES', '.l.favorites');
consts('FAVORITES_BUTTON_TITLE', '.l.favorites_title');
consts('KEY_GLOSSARY_RESULT', '.p.glossary_search_result');
consts('KEY_GLOSSARY_RESULT_TERM', '.p.glossary_search_term');


consts('EVT_WINDOW_LOADED', '.e.win_loaded')

consts('KEY_SHOW_SCROLL_TO_TOP', '.l.show_scroll_to_top');
consts('KEY_DO_NOT_PRESERVE_AR',        '.l.do_not_preserve_ar');
consts('KEY_LNG_NAME', '.p.lng_name');
consts('SHOW_MODAL', '.l.show_modal')
consts('SHOW_MODAL_IN_TOPIC', '.l.show_modal_in_topic')

consts('KEY_HEADER_LOGO_PATH', '.l.header.logo');
consts('KEY_HEADER_TITLE', '.l.header.title');
consts('KEY_HEADER_TITLE_COLOR', '.l.header.title_color');
consts('KEY_HEADER_BACKGROUND_COLOR', '.l.header.background_color');
consts('KEY_LAYOUT_FONT_FAMILY', '.l.layout.font_family');
consts('KEY_HEADER_HTML', '.l.header.html');
consts('KEY_HEADER_CSS', '.l.header.css');
consts('KEY_HEADER_DEFAULT_BACKGROUND_COLOR', '.l.header.default_background_color');
consts('KEY_HEADER_DEFAULT_TITLE_COLOR', '.l.header.default_title_color');
consts('KEY_LAYOUT_DEFAULT_FONT_FAMILY', '.l.layout.default_font_family');

consts('KEY_CUSTOM_BUTTONS', '.l.custom_buttons');
consts('KEY_CUSTOM_BUTTONS_CONFIG', '.l.custom_buttons_config');
consts('KEY_SEARCH_HIGHLIGHT_COLOR', '.l.search_result.highlight_color');
consts('KEY_SEARCH_BG_COLOR', '.l.search_result.highlight_bccolor');
consts('TOPIC_HIGHLIGHTED', '.l.search_result.topic_highlighted');
consts('EVT_REMOVE_HIGHLIGHT', '.e.remove_highlight');


consts('EVT_EXPAND_COLLAPSE_ALL', '.e.expand_collapse_all');
consts('EVT_EXPAND_ALL', '.e.expand_all');
consts('EVT_COLLAPSE_ALL', '.e.collapse_all');
consts('ALL_ARE_EXPANDED', '.l.all_are_expanded');
consts('KEY_CLASSIC_OUTPUT', '.p.classic_output');

