let rh = require("../../lib/rh")
let $ = rh.$
let _ = rh._
let nodeUtils = require("./node_utils")
const util = { 
  scheme(url) {
    let index, scheme
    index = url.indexOf(':')
    if (index !== -1) {
      scheme = url.substring(0, index + 1).toLowerCase().trim()
    }
    return scheme
  }
}
class HtmlResolver {

  get paths(){
    return ['src', 'href'];
  }

  get links(){
    return ['href'];
  }

  constructor(){
  }
  
  resolve(html){
    let nodes = nodeUtils.toHtmlNode(html)
    _.each(nodes, node => {
      if(nodeUtils.isElementNode(node)) {
        $.traverseNode(node, node => this.resolveNode(node))
      }
    })
    return _.reduce(nodes, (result, node) => {
      result += nodeUtils.outerHTML(node)
      return result
    }, '')
  }

  resolveNode(node){
    _.each(this.paths, attribute => this.resovePaths(node, attribute))
    _.each(this.links, attribute => this.resoveLinks(node, attribute))
    return true
  }

  resovePaths(node, attribute) {
    if(!$.hasAttribute(node, attribute)) {
      return
    }
    let value = $.getAttribute(node, attribute)
    $.setAttribute(node, attribute, this.resovePath(value))  
  }

  resoveLinks(node, attribute){
    if(!$.hasAttribute(node, attribute)) {
      return
    }
    $.setAttribute(node, "data-click", "@close(true)")
  }

  resovePath(path) {
    if(!_.isRelativeUrl(path)){
      return path;
    }
    let baseUrl = _.getRootUrl();
    let fullUrl = _.makeFullUrl(path);
    return _.makeRelativeUrl(fullUrl, baseUrl);
  }
  makeFullPath(relUrl, baseUrl) {
    if(!this.isRelativeUrl(relUrl) || this.isRelativeUrl(baseUrl)) {
      return relUrl
    }
    let baseParts = this.filePath(baseUrl).split('/'),
      relPath = this.filePath(relUrl),
      params = relUrl.substring(relPath.length),
      relParts = relPath.split('/')

    if(relParts.length > 1 || relParts[0]) {
      baseParts.pop()
      _.each(relParts, relPart => {
        if(relPart === '..') {
          baseParts.pop()
        } else if(relPart !== '.') {
          baseParts.push(relPart)
        }
      })
    }
    
    return `${baseParts.join('/')}${params}`
  }
  
  isRelativeUrl(url) {
    return !url || !util.scheme(url) && url.trim().indexOf('/')
  }

  filePath(url) {
    let index;
    url = url || ''
    index = url.indexOf('?')
    if (index !== -1) {
      url = url.substring(0, index)
    }
    index = url.indexOf('#')
    if (index !== -1) {
      url = url.substring(0, index)
    }
    return url
  }

}

module.exports = HtmlResolver;