let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;
import NodesUtil from '../../common/nodes'

rh.model.subscribe(consts('EVT_PROJECT_LOADED'),  () =>
  rh.model.subscribe(consts('KEY_MERGED_PROJECT_MAP'), function() {
    let origin = _.getProjectName(_.filePath());
    return rh.model.publish(consts('KEY_TOPIC_ORIGIN'), origin);
  })
);

var ContentFilter = (function() {
  let nonTextNodes = undefined,SELECTED_CLASS;
  ContentFilter = class ContentFilter extends rh.Widget {
    static initClass() {
      SELECTED_CLASS = 'selected'
      nonTextNodes = ['IMG','OBJECT','VIDEO'];
    }

    preventClick(e) {
      _.preventDefault(e);
      return false;
    }

    constructor(config) {
      super(config);
      this.onTagExpr = this.onTagExpr.bind(this);
      this.ids = config.ids;
      this.className = config.className || 'rh-hide';
      this.node = config.node;
      this.hoverClass = 'rh-tag-content-hover';
      this.supClass = 'rh-applied-tag';
      this.createTagNode();

      if (this.ids) {
        rh.model.subscribe(consts('EVT_TOC_SELECTED_ITEM_CHANGED'), this.onTagExpr)        
        this.subscribe(consts('KEY_TOPIC_ORIGIN'), () => {
          return this.subscribe(consts('KEY_TAG_EXPRESSION'), this.onTagExpr);
        });
      } else if (rh._debug) {
        rh._d('error', 'data-tags without any tag combination');
      }
    }

    onTagExpr(tagExprs) {
      if (!_.isDefined(tagExprs)) { 
        tagExprs = this.get(consts('KEY_TAG_EXPRESSION'))
      }
      let origin = this.get(consts('KEY_TOPIC_ORIGIN'));
      if (!this.get('tags')) {
        this.tags = _.union(this.ids, id => _.getTags(id, origin));
        this.publish('tags', this.tags.join(','));
      }

      let apply = !_.any(this.ids, id => _.evalTagExpression(id, tagExprs, origin));

      this.toggleClass(apply);
      if (this.className !== 'rh-hide') { this.toggleClick(apply); }
      return this.applied = apply;
    }

    toggleClass(apply) {
      if (apply) {
        if (!this.applied) { return NodesUtil.onHide(this.node); }
      } else {
        return NodesUtil.onShow(this.node)
      }
    }

    toggleClick(addEvent) {
      if (addEvent) {
        if (!this.applied) { return _.addEventListener(this.node, 'click', this.preventClick); }
      } else {
        if (this.applied) { return _.removeEventListener(this.node, 'click', this.preventClick); }
      }
    }

    onHover() {
      return $.addClass(this.node, this.hoverClass);
    }

    onMouseOut() {
      return $.removeClass(this.node, this.hoverClass);
    }

    appendNode(supNode) {
      let parentNode = this.node;
      let sibling = null;
      if (nonTextNodes.indexOf(this.node.nodeName) !== -1) {
        ({ parentNode } = this.node);
        sibling = this.node.nextSibling;
      }
      if (sibling) {
        parentNode.insertBefore(supNode, sibling);
      } else {
        parentNode.appendChild(supNode);
      }
      return this.resolveDataAttrs(supNode);
    }

    getAptNames() {
      return this.subscribe('tags', () => {
        let data = [];
        _.each(this.tags, (current, idx) => data.push(current.replace("att_sep", ":")));
        return this.publish('showtags', data.join(','));
      });
    }

    createTagNode() {
      return this.subscribe(consts('KEY_SHOW_TAGS'),  showTags => {
        if (!showTags || ($.find(this.node, `sup.${this.supClass}`).length > 0)) { return; }
        let supNode = document.createElement('sup');
        (this.getAptNames)();
        $.setAttribute(supNode, 'data-text', 'showtags');
        $.setAttribute(supNode, 'data-mouseover', 'this.onHover()');
        $.setAttribute(supNode, 'data-mouseout', 'this.onMouseOut()');
        $.setAttribute(supNode, 'class', this.supClass);
        return this.appendNode(supNode);
      });
    }
  };
  ContentFilter.initClass();
  return ContentFilter;
})();

window.rh.widgets.ContentFilter = ContentFilter;
