const rh = require("../../../lib/rh")
const _ = rh._;
let $ = rh.$;
let HtmlResolver = require("../../utils/html_resolver")
let nodeUtils = require("../../utils/node_utils")


class PopupImage {
  constructor(widget, node, rawExpr) {
    $.addClass(node, "popup-image-thumbnail")
    this.node = node;
    rh.model.csubscribe('EVT_PROJECT_LOADED', this._addEnlargeButton.bind(this))
    this.rawExpr = rawExpr
    _.addEventListener(node, "click", this._getClickFn.bind(this));
  }

  _content(rawExpr) {
    let nodes = []
    let imgNode = $.createElement('img')
    $.setAttribute(imgNode, 'src', rawExpr)
    nodes.push(imgNode)

    if ($.hasAttribute(this.node, "usemap")) {
      let mapId = $.getAttribute(this.node, "usemap")
      $.setAttribute(imgNode, 'usemap', mapId)
      nodes.push($(mapId, 0))
    }

    let html_resolver = new HtmlResolver();
    let isInIFrame = _.isIframe()
    if (isInIFrame) {
      return { content: html_resolver.resolve(_.map(nodes, node => nodeUtils.outerHTML(node)).join(' ')), showInTopic: !isInIFrame }
    }
    return { content: _.map(nodes, node => nodeUtils.outerHTML(node)).join(' '), showInTopic: !isInIFrame }
  }

  _getClickFn() {
    let { content, showInTopic } = this._content(this.rawExpr)
    if (!showInTopic) {
      rh.model.cpublish('SHOW_MODAL', { content, isImage: true })
    }
    else {
      rh.model.cpublish('SHOW_MODAL_IN_TOPIC', { content, isImage: true })
    }
  }

  _addEnlargeButton() {

    let img = $.createElement('img', this.node)
    $.setAttribute(img, "src", this.expandImagePath)
    $.addClass(img, "rh-expand-icon")
    nodeUtils.insertAfter(this.node, img)
    _.addEventListener(img, "click", this._getClickFn.bind(this));
  }
  get expandImagePath() {
    let html_resolver = new HtmlResolver();
    let fullImagePath = html_resolver.makeFullPath('template/images/expand.png', _.getRootUrl())
    return _.makeRelativeUrl(fullImagePath)
  }
}

rh.registerDataAttr('popupimage', PopupImage)