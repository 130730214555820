
let rh = window.rh;
let _ = rh._;
let consts = rh.consts;

_.getHostFolder = ((() => {
  let hostFolder;
  hostFolder = null;
  return () => {
    if (hostFolder == null) {
      hostFolder = _.isLocal() ? window.gHostPath : `${document.location.protocol}//${window.gHost}${window.gHostPath}`;
    }
    return hostFolder;
  };
}))();

_.getHostData = rootPath => {
  let absFolder;
  absFolder = window._getFullPath(_.parentPath(), `${rootPath}/`);
  if (window._isHTTPUrl(absFolder)) {
    return {
      gHost: window._getHostNameFromURL(absFolder),
      gHostPath: window._getPathFromURL(absFolder)
    };
  } else {
    return {
      gHost: '',
      gHostPath: absFolder
    };
  }
};

_.getHashMapForRoot = (relUrl, bCsh) => {
  let hashMap;
  let urlhashMap;
  if (bCsh == null) {
    bCsh = null;
  }
  relUrl = _.fixRelativeUrl(relUrl);
  urlhashMap = _.urlParams(_.extractParamString(relUrl));
  if (bCsh) {
    urlhashMap[consts('RHMAPID')] = null;
    urlhashMap[consts('RHMAPNO')] = null;
  }
  hashMap = _.extend(_.urlParams(), urlhashMap);
  hashMap = _.fixHashMapForRoot(hashMap);
  //hashMap = _.addRHSParams(hashMap);
  return hashMap;
};

_.getParamsForRoot = (relUrl, bCsh) => {
  let queryMap;
  let queryString;
  if (bCsh == null) {
    bCsh = null;
  }
  queryMap = _.getHashMapForRoot(relUrl, bCsh);
  queryString = _.mapToEncodedString(queryMap);
  if (queryString.length === 0) {
    return '';
  } else {
    return `?${queryString}`;
  }
};

_.isRootUrl = absUrl => {
  let fileName;
  let filePath;
  let rootUrl;
  if (absUrl == null) {
    absUrl = decodeURI(document.location.href);
  }
  rootUrl = _.getRootUrl();
  filePath = _.filePath(absUrl);
  if (filePath === _.filePath(rootUrl)) {
    return true;
  }
  fileName = _.getFileName(rootUrl);
  return (fileName === 'index.htm' || fileName === 'index.html') && filePath === _.parentPath(rootUrl);
};

_.isExternalUrl = absUrl => {
  let hostFolder;
  if (rh.model.get(rh.consts('KEY_PUBLISH_MODE'))) {
    hostFolder = rh.model.get(rh.consts('KEY_PROJECTS_BASE_URL'));
  } else {
    hostFolder = _.getHostFolder();
  }

  return !(_.equalUrlBase(absUrl, hostFolder) && _.urlStartsWith(absUrl, hostFolder))
};

_.fixHashMapForRoot = hashMap => {
  let HASH_KEY_RANDOM;
  let HASH_KEY_RH_HIGHLIGHT;
  let HASH_KEY_RH_SEARCH;
  let HASH_KEY_RH_TOCID;
  let HASH_KEY_TOPIC;
  let HASH_KEY_UIMODE;
  if (hashMap == null) {
    hashMap = {};
  }
  HASH_KEY_RH_SEARCH = consts('HASH_KEY_RH_SEARCH');
  HASH_KEY_TOPIC = consts('HASH_KEY_TOPIC');
  HASH_KEY_UIMODE = consts('HASH_KEY_UIMODE');
  HASH_KEY_RH_TOCID = consts('HASH_KEY_RH_TOCID');
  HASH_KEY_RH_HIGHLIGHT = consts('HASH_KEY_RH_HIGHLIGHT');
  HASH_KEY_RANDOM = consts('HASH_KEY_RANDOM');
  if (!hashMap[HASH_KEY_UIMODE]) {
    hashMap[HASH_KEY_UIMODE] = null;
  }
  hashMap[HASH_KEY_RANDOM] = null;
  if (!hashMap[HASH_KEY_RH_TOCID]) {
    hashMap[HASH_KEY_RH_TOCID] = null;
  }
  if (!hashMap[HASH_KEY_RH_HIGHLIGHT]) {
    hashMap[HASH_KEY_RH_HIGHLIGHT] = null;
  }
  if (!hashMap[HASH_KEY_RH_SEARCH]) {
    hashMap[HASH_KEY_RH_SEARCH] = null;
  }
  return hashMap;
};

_.fixRelativeUrl = filePath => {
  if (filePath == null) {
    filePath = '';
  }
  filePath = filePath.replace(/\/\.\//g, '/');
  if (filePath[0] === '.' && filePath[1] === '/') {
    filePath = filePath.substring(2);
  }
  return filePath;
};

_.ensureSlash = url => {
  if ((url != null) && url.substr(-1) !== '/') {
    url += '/';
  }
  return url;
};

_.isUrlAllowdInIframe = ((() => {
  let ALLOWED_EXTS_IN_IFRAME;
  ALLOWED_EXTS_IN_IFRAME = ['', '.htm', '.html', '.asp', '.aspx'];
  return absUrl => {
    let ext;
    let relUrl;
    if (_.isExternalUrl(absUrl)) {
      return false;
    }
    relUrl = absUrl.substring(_.getHostFolder().length);
    ext = _.getFileExtention(relUrl).toLowerCase();
    return ALLOWED_EXTS_IN_IFRAME.includes(ext);
  };
}))();

_.splitUrl = url => {
  const element = document.createElement('a');//any non empty element
  element.setAttribute('href', url);
  let { hostname, port, search, pathname, protocol } = element
  !pathname.startsWith('/') ? pathname = '/' + pathname : ''
  return { hostname, port, search, pathname, protocol }
};

_.equalUrlBase = (url1, url2) => {
  let urlParts1 = _.splitUrl(url1);
  let urlParts2 = _.splitUrl(url2);

  return urlParts1.protocol === urlParts2.protocol && urlParts1.hostname === urlParts2.hostname && _.equalUrlPorts(urlParts1, urlParts2)
};

_.equalUrlPorts = (parts1, parts2) => {
  let defaultPort = parts1.protocol === 'http' ? rh.consts('HTTP_PORT') : (parts1.protocol === 'https' ? rh.consts('HTTPS_PORT') : '')

  return parts1.port === parts2.port || (!parts1.port && parts2.port === defaultPort) ||
    (!parts2.port && parts1.port === defaultPort)
};

_.urlStartsWith = (url, base) => {
  let urlParts1 = _.splitUrl(url);
  let urlParts2 = _.splitUrl(base);
  let path1 = urlParts1.pathname && urlParts1.pathname.toLowerCase()
  let path2 = urlParts2.pathname && urlParts2.pathname.toLowerCase()
  return path1.startsWith(path2)
};