let rh = require("../../lib/rh")
let _ = rh._
let consts = rh.consts

_.goToHome = (hash_map, params_map) => {
  let home_path = consts('HOME_FILEPATH')
  if(home_path) {
    let home_url = _.makeFullUrl(home_path)
    let paramsStr = (params_map === undefined)? '' : '?'+ _.mapToEncodedString(params_map)
    let hashStr = (hash_map === undefined)? '' : '#'+ _.mapToEncodedString(hash_map)

    home_url = `${home_url}${paramsStr}${hashStr}`
    document.location = home_url
  }
};

_.isHomeUrl =(url) =>{
  let home_path = consts('HOME_FILEPATH')
  let rootUrl = _.getRootUrl()
  let relativePath = _.makeRelativePath(url, rootUrl)
  let filePath = _.filePath(relativePath)
  return (home_path === filePath)
}

_.compare = (word1, word2) => {
  return (word1 === word2) ? 0 :-1;
};
