const rh = require("../../../lib/rh")

class Focusif {
  constructor (widget, node, rawExpr) {
    widget.subscribeDataExpr(rawExpr, result => {
      if(result) {
        node.focus()
      }
    })
  }
}

rh.registerDataAttr('focusif', Focusif)