let { rh } = window;
let { _ } = rh;
let { model } = rh;
let { consts } = rh;

let KEY_MERGED_PROJECT_MAP = consts('KEY_MERGED_PROJECT_MAP');

_.parseProjectName = project => project.replace(/\.\//g, '').replace(/\.$/, '').replace(/\/$/, '');

_.mapTagIndex = function(idx, project) {
  if (project == null) { project = ''; }
  if (idx) {
    return idx + '+' + project;
  }
  return idx;
};

_.getTags = function(idx, project) {
  if (project == null) { project = ''; }
  if (idx == null) { return idx; }
  let idmap = model.get(KEY_MERGED_PROJECT_MAP);
  let len = idx.indexOf('+');
  if (len !== -1) {
    project = idx.substring(len + 1, idx.length);
    idx = idx.substring(0, len);
  }
  project = _.parseProjectName(project);
  if ((idmap[project] != null ? idmap[project][idx] : undefined) != null) { return idmap[project][idx]; }
  return idx;
};

_.getProjectName = function(url) {
  let idmap = model.get(KEY_MERGED_PROJECT_MAP);
  if ((url != null) && (idmap != null)) {
    url = _.parentPath(url);
    let relUrl = _.makeRelativePath(url, _.getHostFolder());
    relUrl = _.parseProjectName(relUrl);
    while ((idmap[relUrl] == null)) {
      let n = relUrl.lastIndexOf('/');
      if (n < 0) {
        relUrl = '';
        break;
      }
      relUrl = relUrl.substring(0,n);
    }
    url = relUrl;
  }
  return url;
};

_.evalTagExpression = function(index, groupExprs, project) {
  if (project == null) { project = ''; }
  if (!groupExprs || (groupExprs.length === 0)) { return true; }
  
  let tags = _.getTags(index, project);
  if (!tags || (tags.length === 0)) { return true; }
  
  // TODO: fix empty string in robohelp
  if ((tags.length === 1) && ((tags[0] === '') || (tags[0] === '$'))) { return true; }

  let trueFlag = false;
  let falseFlag = _.any(groupExprs, function(item) {
    if (_.evalMultipleTagExpression(item.c, tags)) {
      trueFlag = true;
    } else if (item.c.length) {
      if (_.evalMultipleTagExpression(item.u, tags)) { return true; }
    }
    return false;
  });
  
  if (falseFlag) { return false; } else { return trueFlag; }
};

_.evalMultipleTagExpression = (exprs, tags) => _.any(exprs, expr => _.evalSingleTagExpression(expr, tags));

_.evalSingleTagExpression = (function() {
  let cache = {};
  let operators = ['&', '|', '!'];
  let evalAnd = function(stack) {
    let items = stack.splice(stack.length - 2);
    return stack.push((items[0] === 1) && (items[1] === 1) ? 1 : 0);
  };

  let evalOr = function(stack) {
    let items = stack.splice(stack.length - 2);
    return stack.push((items[0] === 1) || (items[1] === 1) ? 1 : 0);
  };

  let evalNot = function(stack, tags) {
    let items = stack.splice(stack.length - 1);
    return stack.push(items[0] === 1 ? 0 : 1);
  };

  return function(expr, tags) {
    let key = `${expr}:${tags}`; // TODO: now robohelp should export tags as string
    let result = cache[key];
    if (result != null) { return result; }

    let tokens = _.map(expr.split(' '), function(item) {
      if (-1 !== operators.indexOf(item)) { return item; }
      if (-1 === tags.indexOf(item)) { return 0; } else { return 1; }
    });

    if (tokens.length > 1) {
      let stack = [];
      for (let token of Array.from(tokens)) {
        switch (token) {
          case '&': evalAnd(stack); break;
          case '|': evalOr(stack); break;
          case '!': evalNot(stack); break;
          default: stack.push(token);
        }
      }
      result = stack[0];
    } else {
      result = tokens[0];
    }
    
    return cache[key] = result;
  };
})();
