let { rh } = window;
let { _ } = rh;
let { $ } = rh;
let { consts } = rh;
let { model } = rh;

_.hookClick = function(event) {
  let href;
  if ('button' in event && (event.button !== 0)) { return; }
  if (event.defaultPrevented) { return; }
  
  let { body } = document;
  let node = event.target;
  while (true) {
    if (!node || (node === document)) { break; }
    href = $.getAttribute(node, 'href');
    if (href) { break; }
    node = node.parentNode;
  }
  if (!href) { return; }
  href = decodeURI(href);
  let mobileAppMode = model.get(consts('KEY_MOBILE_APP_MODE'));

  let target = $.getAttribute(node, 'target');
  if (target && (target !== '_self') && !mobileAppMode) { return; }
  
  if ((href[0] === '#') && _.isRootUrl()) {
    if (href.length > 1) {
      let bookmarkKey = `${consts('EVT_BOOKMARK')}${href}`;
      if (model.isSubscribed(bookmarkKey)) {
        model.publish(bookmarkKey, '');
      } else {
        model.publish(consts('EVT_NAVIGATE_TO_URL'),
          {absUrl: `${(_.getRootUrl)()}${href}`});
      }
    }
    return _.preventDefault(event);
  } else if (_.isValidFileUrl(href)) {
    let absUrl;
    if (_.isRelativeUrl(href)) { absUrl = window._getFullPath(_.parentPath(), href); }
    if (absUrl == null) { absUrl = href; }

    if ((mobileAppMode || !target) && !_.isUrlAllowdInIframe(absUrl)) {
      return $.setAttribute(node, 'target', '_blank');
    } else {
      model.publish(consts('EVT_NAVIGATE_TO_URL'), {absUrl});
      if (!target) { return _.preventDefault(event); }
    }
  }
};

